import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { makeTransparent, normalize } from './chart_controller/hex_colors';
import filtersPlugin from './chart_controller/plugins/filters';
import legendPlugin from './chart_controller/plugins/legend';
import { DEFAULT_POINT_SHAPE } from './chart_controller/shapes_and_dashes';
import { emphasizeActiveSeries } from './chart_controller/fade_inactive_series';
import {
  withDisconnectBeforeCache,
  withPreviewPrevention,
} from '../javascript/lib/stimulus_turbo';
import { highlightSeriesWithTooltip } from './chart_controller/plugins/highlight_series_with_tooltip_plugin';
import { KeyboardNavigationPlugin } from './chart_controller/plugins/keyboard_navigation_plugin';
import { announceTooltips } from './chart_controller/plugins/announce_tooltips_plugin';

Chart.register(annotationPlugin);

const BRAND_ORANGE = '#ec8333';
const LABEL_COLOR = '#000';
const TICK_BORDER_COLOR = '#A1A6AA';

const SECONDS_IN_WEEK = 60 * 60 * 24 * 7;
const SECONDS_IN_MONTH = 60 * 60 * 24 * 30;

export default turboAware(
  class extends Controller {
    static values = {
      data: Array,
      options: Object,
      properties: Array,
    };

    static targets = [
      'canvas',
      'before',
      'after',
      'filtersContainer',
      'controlsContainer',
      'filters',
      'legendContainer',
      'legendTemplate',
      'legend',
      'announcements',
      'toggleByAlertRadio',
    ];

    connect() {
      // Only render filters, legend and controls if there's data to render
      if (this.dataValue.length) {
        // Set up the filters
        this.filtersContainerTarget.prepend(
          this.beforeTarget.content.cloneNode(true)
        );

        // Set up the legend
        this.legendContainerTarget.prepend(
          this.legendTemplateTarget.content.cloneNode(true)
        );

        // Reveal the controls
        this.controlsContainerTarget.append(
          this.afterTarget.content.cloneNode(true)
        );
      }

      // Make a copy of the propertiesValue that we can mutate
      // otherwise Stimulus seems to read it again and again
      this.categories = this.propertiesValue;

      // Render the chart on the canvas one frame after adding the templates
      // so that the `filtersTarget` is picked up properly
      requestAnimationFrame(() => {
        this.chart = new Chart(this.canvasTarget, {
          type: 'line',
          data: this.chartData,
          options: this.chartOptions,
          plugins: [
            this.hasFiltersTarget &&
              filtersPlugin(this.filtersTarget, this.categories),

            this.hasLegendTarget && legendPlugin(this.legendTarget),

            new KeyboardNavigationPlugin(),
            highlightSeriesWithTooltip(),
            announceTooltips(this.announcementsTarget),
          ],
        });

        // Use a method as listener so it can be removed when disconnecting
        // but we need to bind it so it can access `this.chart` when it runs
        this.clearHoverState = this.clearHoverState.bind(this);
        this.canvasTarget.addEventListener('mouseleave', this.clearHoverState);
      });
    }

    disconnect() {
      this.canvasTarget.removeEventListener('mouseleave', this.clearHoverState);
      this.chart.destroy();
    }

    toggleDataset(event) {
      // If the selected property doesn't have alerts and the property
      // toggle is set to show alerts, move the toggle back and update
      // property visibilities
      const uncheckedAlertToggle = this.toggleByAlertRadioTargets.filter(
        (button) => !button.checked
      )[0];
      const toggledDataset =
        this.chart.data.datasets[event.target.dataset.chartIndexValue];

      if (
        !toggledDataset.alerted &&
        uncheckedAlertToggle.value === 'selected_properties'
      ) {
        uncheckedAlertToggle.checked = true;

        this.chart.data.datasets.forEach((dataset, index) => {
          this.chart.setDatasetVisibility(
            index,
            dataset.visibleBeforeFiltering
          );
        });
      }

      const checkboxChartIndexValue = event.target.dataset.chartIndexValue;
      this.chart.setDatasetVisibility(
        checkboxChartIndexValue,
        event.target.checked
      );
      this.chart.update();
    }

    toggleAlertInPeriod(event) {
      // Stop the toggle triggering actions from the legend defaults controller
      event.stopPropagation();

      // Toggle the datasets
      this.chart.data.datasets.forEach((dataset, index) => {
        if (event.target.value === 'selected_properties') {
          this.chart.setDatasetVisibility(
            index,
            dataset.visibleBeforeFiltering
          );
        } else {
          dataset.visibleBeforeFiltering = this.chart.isDatasetVisible(index);
          this.chart.setDatasetVisibility(index, dataset.alerted);
        }
      });
      this.chart.update();

      // There are duplicated radio buttons for mobile & desktop.
      // When a button is changed, find the other instance and check it.
      this.toggleByAlertRadioTargets
        .filter((input) => input.value === event.target.value)
        .forEach((input) => (input.checked = true));
    }

    clearHoverState() {
      requestAnimationFrame(() => {
        emphasizeActiveSeries(this.chart);
        this.chart.update();
      });
    }

    getSeriesPositions() {
      const result = {};
      this.categories.forEach((category, categoryIndex) => {
        category.properties.forEach((property, propertyIndex) => {
          result[property.name] = { categoryIndex, propertyIndex };
        });
      });
      return result;
    }

    get chartData() {
      const seriesPositions = this.getSeriesPositions();

      return {
        datasets: this.dataValue
          // Order the chart series based on their position
          // in the legend, to make sure there's at least
          // some logic to how the user cycles through them
          .sort((a, b) => {
            const positionForA = seriesPositions[a.name];
            const positionForB = seriesPositions[b.name];

            return (
              // In case both have the same `categoryIndex`, the `||`
              // will fallback to the `propertyIndex` comparison
              // as the difference will be 0 (which is falsy)
              positionForA.categoryIndex - positionForB.categoryIndex ||
              positionForA.propertyIndex - positionForB.propertyIndex
            );
          })
          .map((data) => {
            return {
              id: data.id,
              label: data.name,
              hidden: data.hidden,
              yAxisID: `yAxis-${data.key}`,
              data: data.values,
              alerted: data.alerted,
              borderColor: normalize(data.color) || '#000000',
              borderWidth: 2,
              hoverBorderWidth: 2,
              pointBackgroundColor: data.color,
              pointBorderWidth: 1,
              pointBorderColor: '#ffffff',
              pointHoverBorderWidth: 1,
              // Prevent the cubic interpolation to get the line going
              // above or below local minimum/maximum values to avoid
              // giving a false sense that the values went lower/higher
              cubicInterpolationMode: 'monotone',
              ...DEFAULT_POINT_SHAPE,
              tooltip: {
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += context.raw.formatted;
                    }
                    return label;
                  },
                },
              },
            };
          }),
      };
    }

    get chartOptions() {
      return {
        layout: {
          autoPadding: false,
        },
        plugins: {
          legend: {
            // Legend is rendered in HTML for better accessibility
            display: false,
            labels: {
              usePointStyle: true,
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'y',
            },
            zoom: {
              wheel: {
                enabled: true,
                // Prevent highjacking regular scroll
                // 'alt' used to avoid highjacking browser zoom too
                modifierKey: 'alt',
              },
              pinch: {
                enabled: true,
              },
              mode: 'y',
            },
          },
          annotation: {
            annotations: {
              lower: {
                type: 'box',
                yMax: this.optionsValue.amber_chart_section_percent / 100,
                borderWidth: 0,
                backgroundColor: makeTransparent(BRAND_ORANGE, '40'),
              },
              higher: {
                type: 'box',
                yMin: 1 - this.optionsValue.amber_chart_section_percent / 100,
                borderWidth: 0,
                backgroundColor: makeTransparent(BRAND_ORANGE, '40'),
              },
            },
          },
        },
        tension: 0.3,
        hover: {
          mode: 'dataset',
        },
        elements: {
          point: {
            // Slightly enlarged hit radius to make tooltip appear more easily
            hitRadius: 10,
          },
        },
        animation: {
          duration: 300,
        },
        scales: Object.assign(this.scaleOptions, {
          x: {
            type: 'time',
            min: this.optionsValue.xmin,
            max: this.optionsValue.xmax,
            time: {
              unit: this.xAxisUnit,
              tooltipFormat: 'E dd LLLL h:m aaa',
              displayFormats: {
                day: 'E', // Mon, Tue, Wed...
                week: 'MMM do', // Jan 21st, Feb 5th...
                month: 'MMM ', // Jan, Feb, Mar...
              }, // https://date-fns.org/v2.22.1/docs/format
            },
            ticks: {
              padding: 20,
              color: LABEL_COLOR,
              callback: (tick) => tick.toUpperCase(),
            },
            grid: {
              display: true,
              drawTicks: false,
              color: TICK_BORDER_COLOR,
              borderDash: [5, 5],
            },
          },
          // Normalized y scale for rendering annotations
          y: {
            min: 0,
            max: 1,
            display: false,
          },
        }),
      };
    }

    get xAxisUnit() {
      const rangeDurationInSeconds =
        (new Date(this.optionsValue.xmax) - new Date(this.optionsValue.xmin)) /
        1000;

      if (rangeDurationInSeconds > 4 * SECONDS_IN_MONTH) {
        return 'month';
      } else if (rangeDurationInSeconds > 3 * SECONDS_IN_WEEK) {
        return 'week';
      } else {
        return 'day';
      }
    }

    get scaleOptions() {
      return Object.fromEntries(
        this.dataValue.map((data) => {
          return [
            `yAxis-${data.key}`,
            {
              min: data.ymin,
              max: data.ymax,
              display: false,
            },
          ];
        })
      );
    }
  }
);

function turboAware(controller) {
  controller.prototype.connect = withPreviewPrevention(
    withDisconnectBeforeCache(controller.prototype.connect)
  );
  // As we're not connecting, there's no need to disconnect
  // either when running a preview. This avoids putting checks
  // in the controllers for missing variables that only happen
  // because the controller was not connected on a preview page
  controller.prototype.disconnect = withPreviewPrevention(
    controller.prototype.disconnect
  );

  return controller;
}
