import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fieldset'];

  // Usage:
  // Add data-action: "change->checkbox-toggled-fieldset#toggle" to the checkbox input
  // Add data-checkbox-toggled-fieldset-target="fieldset" to the fieldset that should be toggled

  toggle(event) {
    if (event.target.checked) {
      this.fieldsetTarget.classList.remove('d-none');
      this.disableFormFields(false);
    } else {
      this.fieldsetTarget.classList.add('d-none');
      this.disableFormFields(true);
    }
  }

  disableFormFields(value) {
    Array.from(this.fieldsetTarget.elements).forEach((element) => {
      if (
        element.tagName === 'INPUT' ||
        element.tagName === 'SELECT' ||
        element.tagName === 'TEXTAREA'
      ) {
        element.disabled = value;
      }
    });
  }
}
